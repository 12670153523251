@import '../../common-scss/_variables.scss';

.listSection {
    margin: 0px 15px 10px 15px;
    max-width: 2200px;
}
.titleLatest {
    font-family: Roboto Condensed;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: var(--color-000-fff);
    margin: 0 15px;
}
.allCards.addExtraPaddingTop {
    padding-top: 20px;
}
.allCards {
    padding-top: 20px;
    max-width: 2000px;

    .cardList {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        height: 100%;
        .cardDiv {
            margin: 25px auto;
            &:not(.introDiv) {
                max-width: 450px;
            }
        }
    }
}

@media only screen and (max-width: 340px) {
    .allCards {
        .cardList {
            .cardDiv {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: $tabletBreakpoint) and (max-width: $desktopBreakpoint) {
    .listSection {
        max-width: 1000px;
        margin: auto;
    }
    .allCards {
        .cardList {
            .cardDiv {
                width: 46%;
                margin: 25px 1.2vw;
            }
        }
    }
}
@media only screen and (min-width: $desktopBreakpoint) and (max-width: $largeDesktopBreakpoint) {
    .listSection {
        margin: 0px 0px 10px 0px;
        max-width: 1000px;
        margin: auto;
    }
    .titleLatest {
        font-size: 26px;
    }
    .allCards {
        .cardList {
            .cardDiv {
                width: 46%;
                margin: 30px 1.2vw;
            }
        }
    }
}
@media only screen and (min-width: $largeDesktopBreakpoint) {
    .listSection {
        margin: 0px 0px 10px 0px;
    }
    .titleLatest {
        font-size: 26px;
    }
    .allCards {
        .cardList {
            .cardDiv {
                margin: 1.2vw 20px;
                width: 30%;
            }
        }
    }
}
