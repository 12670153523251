@import '_variables.scss';

@mixin flex($justify) {
    display: flex;
    justify-content: $justify;
}
@mixin flexDirection($justify, $direction) {
    display: flex;
    justify-content: $justify;
    flex-direction: $direction;
}
.footer {
    background-color: var(--color-292929-000);

    .footerDIV {
        padding: 10px 10px 5px 10px;
        font-family: 'Roboto Condensed', sans-serif;
        .footerLinks {
            @include flexDirection(space-between, column);
            text-align: center;
            margin: 25px 0;
            font-weight: 700;
            font-size: 18px;
            color: var(--color-a3a3a3-f5f5f5);
        }
        .footerSocialMedia {
            @include flex(space-evenly);
            align-items: center;
            padding: 15px 50px;
            max-width: 600px;
            margin: auto;

            fill: var(--color-a3a3a3-f5f5f5);

            a {
                fill: var(--color-a3a3a3-f5f5f5);
            }
        }
    }
    .subFooter {
        @include flexDirection(space-between, column);
        ul {
            @include flexDirection(center, row);
            flex-wrap: wrap;
            li {
                a {
                    font-family: 'Roboto Condensed', sans-serif;
                    font-size: 14px;
                    line-height: 16px;
                    color: var(--color-a3a3a3-f5f5f5);
                    margin: 0 0 0 15px;
                }
            }
        }
    }
    .footerCopyright {
        font-size: 12px;
        line-height: 14px;
        color: var(--color-a3a3a3-f5f5f5);
        text-align: center;
        margin: 20px 0px 30px 0px;
        padding: 0 50px;
        font-family: 'Roboto Condensed', sans-serif;
    }
    .newsletterDesktop {
        display: none;
    }
}
@media only screen and (min-width: $desktopBreakpoint) {
    .footer {
        background-color: #292929;
    }
    .footerDIV {
        height: 180px;
        display: block;
        padding: 10px 0px 5px 0px;
        margin: 0 80px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .footerLinks {
            width: 15%;
        }
        .footerSocialMedia {
            padding: 0px 0px;
            width: 30%;
        }
        .newsletterDesktop {
            display: flex;
            justify-content: center;
            width: 30%;
        }
    }
    .subFooter {
        background-color: black;
        padding: 30px 0 10px 0;
        ul {
            display: flex;
            justify-content: center;
            li {
                a {
                    // line-height: 42px;
                    color: var(--color-a3a3a3-f5f5f5);
                    margin: 10px 0 20px 10px;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
        .footerCopyright {
            text-align: center;
            font-size: 14px;
            line-height: 16px;
            color: var(--color-a3a3a3-f5f5f5);
            margin: 0px 0px 0px 0px;
            padding: 10px 0 20px 0;

            .break {
                display: none;
            }
        }
    }
}
@media only screen and (min-width: $largeDesktopBreakpoint) {
    .footerDIV {
        margin: 0 400px;
    }
}
