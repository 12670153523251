@import '../_variables.scss';
.wrapper {
    background-color: var(--color-fff-000);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    width: 100%;
    flex-direction: row;
    z-index: 100;
    border-bottom: 1px solid var(--color-fff-292929);
    display: block;
    left: 0;
    height: 60px;

    .content {
        display: flex;
        flex-direction: row;
        padding: 0px 8px;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        .left {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .hamburgerMenu {
                display: flex;
                align-items: center;
            }
            .logoMobile {
                text-align: center;
            }
            .holidayLogo {
                width: 30%;
                svg {
                    fill: var(--color-000-f5f);
                    stroke: var(--color-000-f5f);
                }
            }
        }
        .toolWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .tool {
                display: flex;
                color: #fff;
                align-items: center;
            }
        }
    }
}

.navLogo {
    width: 120px;
    height: auto;
    fill: var(--color-000-f5f);
}

@media only screen and (max-width: 350px) {
    .wrapper .content {
        padding: 10px 10px 10px 15px;
    }
}
@media only screen and (min-width: $tabletBreakpoint) {
    .wrapper {
        .content {
            padding: 0 20px;
        }
    }
}
@media only screen and (min-width: $desktopBreakpoint) {
    .wrapper {
        padding: 5px 3vw 5px $desktopPadding;
        .content {
            justify-content: space-between;

            .left {
                width: 60%;
                .logoMobile {
                    display: flex;
                    justify-content: flex-start;
                }
                .hamburgerMenu {
                    display: none;
                }
            }
            .navLogo {
                width: 145px;
                height: 100%;
            }
        }
    }
}
@media only screen and (min-width: $largeDesktopBreakpoint) {
    .wrapper {
        padding: 5px 4vw 5px 4vw;
        .content {
            max-width: 1760px;
            margin: auto;
        }
    }
}

@media only screen and (max-width: 415px) {
    .wrapper .content {
        .holidayHamburger {
            width: 40px;
        }
        .holidayLogo {
            width: 60%;
            display: flex;
            justify-content: flex-end;
        }
    }
}
