.container {
    width: 100%;
    height: 66px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;

    a {
        width: 100%;
    }
}

.button {
    flex: 1 1 0;
    height: 66px;
    padding: 9px 15px;
    background: #f8bf00;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.text {
    flex: 1 1 0;
    text-align: center;
    color: black;
    font-size: 16px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 24px;
    word-wrap: break-word;
}
