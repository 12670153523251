@import '_variables.scss';
.my90 {
    margin-top: 20px;
}
.default {
    margin-top: 90px;
}

.wrapper {
    padding: 0;
    margin: 0;
    width: 100%;
    background: var(--color-fff-000);
}
.container {
    display: flex;
    max-width: 1920px;
    flex-flow: row nowrap;
    padding: 0 $desktopPadding;
    justify-content: center;
    background: none;

    margin-right: auto;
    margin-left: auto;
}

.leftContainer.closedMobile {
    display: block;
}
.leftContainer.openMobile {
    display: block;
}

.leftContainer {
    width: 300px;
    background: var(--color-leftMenuLight-leftMenuDark);
    border-radius: 6px;
    margin-right: 2vw;
    margin-top: 0;
    padding-bottom: 90px;
    height:100%;
}
.rightContainer {
    width: 100%;
    overflow: hidden;
    background: var(--color-fff-000);
}

.newsletterMobileOnly {
    display: none;
}

@media only screen and (max-width: $desktopBreakpoint) {
    .container {
        padding: 0;
    }
    .leftContainer.openMobile {
        display: block;
        position: absolute;
    }
    .leftContainer.closedMobile {
        display: none;
    }
    .leftContainer {
        width: 0%;
    }
    .rightContainer {
        width: 100%;
        padding: 0;
    }
    .newsletterMobileOnly {
        display: block;
    }
    .transparencyDiv {
        transition: 0.1s ease-in-out;
        z-index: 4;
        position: fixed;
        right: 0;
        bottom: 0;
        top: 60px;
        width: 100%;
        height: 100%;
        display: none;
        &.transparencySheen {
            display: block;
            visibility: visible;
            background: rgba(0, 0, 0, 0.5803921568627451);
        }
    }
}
@media only screen and (min-width: $tabletBreakpointMax) {
    .container {
        padding: 0 $largeDesktopPadding;
    }
    .my90 {
        margin-top: 30px;
        padding: 0;
    }
    .default {
        margin-top: 80px;
        padding: 0;
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .my90 {
        margin-top: 40px;
        padding: 0 4vw;
    }
    .default {
        margin-top: 100px;
        padding: 0 4vw;
    }
}

@media only screen and (min-width: $largeDesktopBreakpoint) {
    .rightSide {
        width: 100%;
    }
    .leftContainer {
        width: 300px;
    }

    .my90 {
        margin-top: 30px;
    }
    .default {
        margin-top: 120px;
    }
}
