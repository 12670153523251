.wrapper {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    height: 100%;

    margin-top: 60px;
    background: var(--color-f5f5f5-292929);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

    padding: 10px 20px;
    color: var(--color-292929-CCC);
    display: inherit;

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 760px;
        margin: auto;
        text-align: center;
        overflow: hidden;
        text-overflow: break-all;
        white-space: normal;

        a {
            color: var(--color-2f72eb-4b85ee);
            font-weight: bold;
        }

        p {
            color: var(--color-292929-CCC);
        }
    }
}

.button {
    cursor: pointer;
    background-color: transparent;
    svg {
        stroke: var(--color-000-f5f);
    }
}
