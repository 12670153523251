@import '../../../common-scss/_variables.scss';

.button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:focus {
        outline: none;
    }
    .bar {
        width: 1.8rem;
        height: 0.2rem;
        background: var(--color-000-fff);
        border-radius: 5px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
    }
    &.open {
        .bar {
            &:first-child {
                transform: rotate(37deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotate(-37deg);
            }
        }
    }
}
