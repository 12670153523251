@import '../common-scss/_variables.scss';

.promoVideo {
    position: fixed;
    bottom: 15px;
    left: 4px;
    z-index: 99;
    .promoVideoContainer {
        position: relative;
        background-color: rgba(1, 1, 1, 0);
        border: none;
        .closeIcon {
            position: absolute;
            top: -15px;
            right: -15px;
            z-index: 100;
            .closeButton {
                background-color: #353535;
                stroke: white;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.5s;
            }
        }
        .closeIcon:hover {
            .closeButton {
                transform: rotate(90deg);
            }
        }
        .promoContainer {
            height: 200pt;
            width: 150pt;
            border-radius: 6px;
            overflow: hidden;
        }
    }
}
