.paginationContainer {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /*firefox*/

    .paginationList {
        width: 100%;
        display: flex;
        overflow-x: auto;
        //encountered this issue here where I had to justify-content:center them but also make the overflow-x work.
        //if you have overflow-x scroll and justify-content:center, then the left side won't be visible while scrolling.
        //followed this article and found this solution https://bhch.github.io/posts/2021/04/centring-flex-items-and-allowing-overflow-scroll/
        .pageItem:first-child {
            margin-left: auto;
        }
        .pageItem:last-child {
            margin-right: auto;
        }

        .pageItem {
            background: var(--color-fff-000);
            border: 2px solid var(--color-388713-419d16);
            max-width: 42px;
            border-radius: 6px;
            margin: 0 4px;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            justify-content: center;
            &.selected {
                background: #216600;
                a,
                span {
                    color: white;
                }
            }
            a,
            span {
                font-family: 'Roboto Condensed';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                color: var(--color-388713-419d16);
                padding: 11px 16px;
            }
        }
    }
}
