@import '_variables.scss';

.newsLetterSection {
    font-family: 'Roboto Condensed', sans-serif;
    .formContainer {
        background-color: var(--color-4d4d4d-292929);
        color: var(--color-a3a3a3-f5f5f5);
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 150px;

        h2 {
            color: white;
            font-weight: bold;
            font-size: 19px;
            margin: 10px 0px 0 0;
        }
        .form {
            display: flex;
            width: 90vw;
            max-width: 300px;
            margin: 10px 0px;
            color: var(--color-a3a3a3-fff);

            input {
                width: 50vw;
                height: 35px;
                padding-left: 10px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                border: none;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: var(--color-a3a3a3-fff);
                background-color: var(--color-fff-000);

                &:focus {
                    outline: green;
                }
            }
            .submit {
                background-color: $RedeemNow;
                font-family: 'Roboto Condensed', sans-serif;
                color: #000;
                font-weight: bold;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border: none;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
                &:focus {
                    outline: none;
                }
            }
        }
        .notice {
            font-weight: bold;
            font-family: 'Roboto Condensed', sans-serif;
            text-align: center;
            padding: 0 10px;
        }
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .newsLetterSection .formContainer {
        background-color: #292929;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: auto;
        h2 {
            text-align: left;
            font-size: 18px;
        }
        .form {
            display: flex;
            width: 90vw;
            max-width: 400px;
            margin: 10px 0px;
            input {
                width: 70%;
                height: 35px;
                font-size: 14px;
                padding-left: 10px;
            }
        }
        .notice {
            padding: 0;
            text-align: left;
        }
    }
}
