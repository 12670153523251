@import '../../../common-scss/_variables.scss';

.wrapper {
    display: flex;
    position: fixed;

    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    background: var(--color-fff-000);

    height: 100dvh;
    width: 60%;

    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    overflow-y: scroll;

    box-shadow: -2px 0px 12px -2px rgba(0, 0, 0, 0.15) inset;
    z-index: 50;
    .subMenu.hidden {
        display: none;
    }   
    .menu,
    .subMenu {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        .menuItem,
        .submenuItem {
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 16px;
            background: var(--color-fff-000);
            border-bottom: 1px solid var(--color-cccccc-4d4d4d);
            letter-spacing: 1px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include text-ellipsis;
            cursor: pointer;

            a {
                @include text-ellipsis;
            }

            .menuItemAnchor {
                display: inline-block;
                width: 100%;
                font-size: 16px;
                color: var(--color-000-a3a3a3);
                text-decoration: none;
                transition: color 0.3s linear;
                padding: 20px;


                @media (max-width: 400px) {
                    text-align: center;
                    font-size: 14px;
                }
            }
            .toggleSubMenu {
                width: 5%;
                background: none;
                cursor: pointer;
                padding: 20px 30px 20px 10px;
            }
        }
        .submenuItem {
            background: var(--color-f5f5f5-1c1c1);
            padding-left:10px;
            .menuItemAnchor {
                color: var(--color-4d4d4d-CCCCC);    
            }
        }
        .menuListItem.holidayLink {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .holidayIcon {
                width: 10px;
            }
            .Link {
                padding-left: 16px;
                text-align: left;
            }
        }
        .categoriesLinksDiv {
            background-color: #f5f5f5;
            ul {
                list-style: none;
                margin-left: 40px;
                padding: 10px 0;

                .categoriesLink {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    a {
                        font-family: Arial;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #7a7a7a;
                        width: 100%;
                        height: 100%;
                        padding: 15px 0;
                    }
                }
                .line {
                    height: 1px;
                    width: 100%;
                    border: none;
                    background: #cccccc;
                    margin-left: -20px;
                }

                .categoriesLink::before {
                    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
                    color: #a3a3a3; /* Change the color */
                    font-weight: bold; /* If you want it to be bold */
                    display: inline-block; /* Needed to add space between the bullet and the text */
                    width: 0.7em; /* Also needed for space (tweak if needed) */
                    margin-left: -1em; /* Also needed for space (tweak if needed) */
                    font-size: 20px;
                    opacity: 0.9;
                }
            }
        }

        .list.noBottomBorder {
            border-bottom: none;
        }
    }
}
#ab-test-menu-link {
    display: none;
}
@media only screen and (max-width: 450px) {
    .wrapper {
        height: 100dvh;

        .menu {
            padding-top: 60px;
            height: 100dvh;
        }
    }
    .wrapper .list .menuItem .menuItemAnchor {
        text-align: left;
    }
}

@media only screen and (min-width: 600px) {
    .wrapper {
        width: 30%;
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .wrapper {
        position: relative;
        background-color: var(--color-fff-000);
        height: auto;
        top: 25px;
        left: 0;
        width: 80%;
        border-radius: 6px;
        margin: 0 auto;
        overflow-y: hidden;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
        display: block;
    }
}
