@import '../../common-scss/_variables.scss';

// Constants
$thumbBottomDistanceMobile: 47px;
$thumbBottomDistanceDesktop: 61px;

// Mixins
@mixin textEllipsis($width) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: $width;
}

// Base Card Container
.cardContainer {
    // Layout
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    // Appearance
    background: var(--color-fff-121212);
    -webkit-filter: --var(--color-cardShadowLight-cardShadowDark);
    filter: var(--color-cardShadowLight-cardShadowDark);

    // Hover state
    &:hover {
        -webkit-filter: drop-shadow(0 0 3px #009c06);
        filter: drop-shadow(0 0 3px #009c06);
    }

    // Flash Sale Variant
    &.flashSaleCard {
        border: 2px dashed var(--bg-flashRed);

        .redeemButton {
            background-color: $RedeemNow;
            color: #000;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 700;
        }
    }

    // Card Content Section
    .cardContent {
        .cardThumb {
            position: relative;
            display: table;
            table-layout: fixed;
            width: 100%;

            // Card Title
            .cardTitle {
                color: var(--color-000-f5f);
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 27px;
                font-weight: bold;
                line-height: 27px;
                padding: 15px 10px 5px 10px;
                text-decoration: underline;
                text-transform: uppercase;
                text-underline-offset: 2px;
                @include textEllipsis(100%);
            }

            // Event Label
            .eventLabel {
                position: absolute;
                left: 5px;
                top: 5px;
                display: flex;
                width: 40px;
                height: 40px;
                align-items: center;
                background: #292929;
                border-radius: 100px;
                gap: 10px;
                justify-content: center;

                svg {
                    left: 50%;
                    top: 50%;
                }
            }

            // Category Score Box
            .categoryScoreBox {
                position: absolute;
                bottom: $thumbBottomDistanceMobile;
                right: 0;
                font-family: 'Roboto Condensed', sans-serif;
                width: 100px;

                .catLink {
                    background-color: #000;
                    color: #fff;
                    display: flex;
                    font-size: 12px;
                    justify-content: center;
                    padding: 3px 0;
                    z-index: 1;
                    overflow: hidden;
                }

                .score {
                    background: #292929;
                    color: white;
                    cursor: default;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    font-size: 14px;
                    height: auto;
                    line-height: 1.5;
                    padding: 6px 9px;
                    z-index: 1;

                    span {
                        color: #2dbe60;
                        font-family: 'Roboto Condensed', sans-serif;
                        font-size: 22px;
                        margin-top: -5px;
                        margin-bottom: -5px;
                    }
                }
            }

            // Top Website Label
            .topWebsiteLabel {
                position: absolute;
                bottom: $thumbBottomDistanceMobile;
                left: 0;
                align-items: center;
                background: var(--color-388713-419d16);
                color: #fff;
                display: flex;
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 14px;
                font-weight: 500;
                justify-content: center;
                padding: 5px 8px;
                text-align: center;
                z-index: 1;
            }

            // Promo Labels
            .promoLabel {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                position: absolute;
                top: -30px;
                right: 0;
                z-index: 1;

                // Common styles for all promo labels
                .promoCodeLabel,
                .flashSaleLabel,
                .lifeTimeDiscountLabel {
                    align-items: center;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    font-size: 18px;
                    font-weight: 700;
                    height: 30px;
                    justify-content: center;
                    padding: 0px 10px;
                    white-space: nowrap;

                    p {
                        font-family: 'Roboto Condensed', sans-serif;
                    }
                }

                // Specific label styles
                .promoCodeLabel {
                    background: var(--color-216600-419d16);
                    flex-direction: row;
                    gap: 0.5rem;
                    text-transform: uppercase;
                }

                .flashSaleLabel {
                    background: var(--bg-flashRed);
                }

                .lifeTimeDiscountLabel {
                    background: #004cd5;
                    flex-flow: row nowrap;
                    justify-content: space-between;

                    p {
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    // Card Middle Section
    .cardMiddle {
        padding: 0 10px;

        // Card Prices
        .cardPrices {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            font-family: 'Roboto Condensed', sans-serif;
            height: auto;
            justify-content: space-between;
            margin: 15px 0;
            width: calc(100% - 30px);

            &.italic {
                font-style: italic;

                .rowPricesSale .firstRow .salePrice sup {
                    padding-left: 2px;
                }
            }

            // Regular Prices Row
            .rowPricesRegular {
                align-items: center;
                display: flex;
                flex-flow: row wrap;
                font-family: 'Roboto Condensed', sans-serif;
                justify-content: flex-start;
                line-height: 1.5em;

                .regularPriceNoDiscount {
                    color: var(--color-4d4d4d-f5f5f5);
                    font-size: 39px;
                    font-style: normal;
                    font-weight: bold;
                    line-height: 42px;
                    text-decoration: none;

                    sup {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: bold;
                        line-height: 20px;
                    }
                }

                .priceFrequency {
                    color: var(--color-747474-a3a3a3);
                    font-family: 'Roboto Condensed', sans-serif;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 14px;
                    text-align: center;
                }

                .priceFrequencyNoDiscount {
                    font-style: normal;
                    margin-left: 10px;
                }
            }

            // Sale Prices Row
            .rowPricesSale {
                align-items: flex-start;
                display: flex;
                flex-flow: column nowrap;
                font-family: 'Roboto Condensed', sans-serif;
                justify-content: flex-start;
                line-height: 1.5em;
                width: 190px;

                .firstRow {
                    align-items: center;
                    display: flex;
                    width: 100%;

                    .regularPrice {
                        color: var(--color-4d4d4d-f5f5f5);
                        font-size: 14px;
                        height: auto;
                        line-height: 14px;
                        text-decoration: line-through;
                        text-decoration-color: var(--color-4d4d4d-f5f5f5);
                    }
                    .longListPrice{
                        padding-left:25px;
                    }

                    .salePrice {
                        color: var(--color-e13a3a-e55555);
                        font-family: 'Roboto Condensed', sans-serif;
                        font-size: 39px;
                        font-weight: bold;
                        line-height: 42px;
                        width: 85px;

                        sup {
                            font-family: 'Roboto Condensed', sans-serif;
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 20px;
                        }

                        &.singleDigitSalePrice {
                            width: 70px;
                        }
                    }
                }

                .secondRow {
                    align-items: center;
                    display: flex;
                    width: 100%;

                    .prctOff {
                        color: var(--color-e13a3a-e55555);
                        font-family: 'Roboto Condensed', sans-serif;
                        font-size: 17px;
                        font-weight: 600;
                        line-height: 18px;
                        margin-top: -25px;
                        @media screen and (max-width: $desktopBreakpoint) {
                            margin-top: -15px;
                        }
                    }

                    .priceFrequency {
                        color: var(--color-747474-a3a3a3);
                        display: flex;
                        font-family: 'Roboto Condensed', sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        justify-content: center;
                        line-height: 14px;
                        width: 85px;

                        &.singleDigitPrice {
                            padding-right: 4px;
                            width: 70px;
                        }
                    }
                }
            }
        }

        // Description
        .description {
            color: var(--color-000-ccc);
            display: -webkit-box;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;

            &.longerDesc {
                -webkit-line-clamp: 3;
            }
        }
    }

    // Card Column Layout
    .cardColumm {
        flex-direction: column;
    }

    // Card Bottom Section
    .cardBottom {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        padding: 15px 10px;
        row-gap: 15px;
        width: 100%;

        .fullReviewLinkPromo {
            width: 100%;

            span {
                color: var(--color-216600-419d16);
                font-family: 'Roboto Condensed';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                width: 100%;
            }
        }

        strong {
            font-weight: 700;
        }

        // Site Title Variations
        .fullReviewSiteTitleSmall {
            width: 40%;

            @media screen and (max-width: 350px) {
                max-width: 115px;
            }

            @media screen and (min-width: 351px) {
                max-width: 135px;
            }
        }

        .fullReviewSiteTitleDefault {
            width: 70%;
        }

        // Review Links
        .fullReviewLink {
            color: var(--color-388713-419d16);
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 16px;
            font-style: normal;
            line-height: 16px;
            text-align: left;
            white-space: nowrap;
            @include textEllipsis(100%);

            .noBold {
                font-size: 14px;
                font-weight: 100;
            }
        }

        .fullReviewSiteTitle {
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            text-transform: uppercase;

            @media screen and (max-width: 850px) {
                @include textEllipsis(100%);
            }
        }
    }
}

// Mobile Styles
@media screen and (max-width: 350px) {
    .cardContainer {
        .cardContent .cardThumb .categoryScoreBox {
            bottom: $thumbBottomDistanceMobile;
        }

        .cardMiddle .cardPrices .rowPrices {
            .salePrice,
            .priceFrequency {
                width: 30%;
            }

            .regularPrice,
            .prctOff {
                width: 70%;
            }

            .regularPriceNoDiscount {
                width: 30%;
            }
        }

        .cardBottom {
            display: flex;
            justify-content: flex-start;

            .fullReviewLink {
                color: var(--color-388713-419d16);
                font-size: 10px;
                line-height: 1.42857143;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .fullReviewSiteTitle {
                font-size: 16px;
            }

            .cardButton {
                width: 20px;
            }
        }
    }
}

// Desktop Styles
@media only screen and (min-width: $desktopBreakpoint) {
    .cardContainer {
        .cardContent .cardThumb {
            .categoryScoreBox {
                bottom: $thumbBottomDistanceDesktop;
            }

            .cardTitle {
                font-size: 33px;
                line-height: 36px;
                padding: 15px 20px;
                @include textEllipsis(100%);
            }

            .topWebsiteLabel {
                bottom: $thumbBottomDistanceDesktop;
            }
        }

        .cardMiddle {
            padding: 0 20px;

            .description {
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                line-height: 24px;
                -webkit-line-clamp: 7;

                &.longerDesc {
                    -webkit-line-clamp: 12;
                }
            }

            .fullReviewLinkDesc {
                color: var(--color-388713-419d16);

                &:visited {
                    color: var(--color-388713-419d16);
                }

                &:after {
                    content: '❯';
                    color: var(--color-388713-419d16);
                }
            }

            .cardPrices {
                margin: 15px 0;

                .rowPricesSale {
                    .firstRow {
                        .salePrice {
                            font-size: 42px;
                            line-height: 42px;
                            padding-bottom: 5px;
                            width: 92px;

                            sup {
                                font-size: 21px;
                                line-height: 30px;
                                margin-left: 1px;
                            }
                        }

                        .regularPrice {
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }

                    .secondRow .priceFrequency {
                        width: 92px;
                    }
                }

                .rowPricesRegular {
                    .regularPriceNoDiscount {
                        font-size: 42px;
                        font-style: normal;
                        font-weight: bold;
                        line-height: 42px;
                        text-decoration: none;

                        sup {
                            font-size: 21px;
                            font-style: normal;
                            font-weight: bold;
                            line-height: 30px;
                        }
                    }

                    .priceFrequency {
                        color: var(--color-747474-a3a3a3);
                        font-family: 'Roboto Condensed', sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 14px;
                        margin-left: 10px;
                    }

                    .priceFrequencyNoDiscount {
                        font-style: normal;
                    }
                }
            }
        }

        .cardBottom {
            padding: 15px 20px 20px 20px;
        }
    }
}
